<template>
  <div class="category-page__aside">
    <CategoryFilters class="category-page__filters" :category-id="categoryId" />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CategoryFilters } from '~/components/Category';

export default defineComponent({
  name: 'CategoryAside',
  components: { CategoryFilters },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.category-page__aside {
  padding-right: 3.125rem;
}
</style>
