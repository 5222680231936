<template>
  <div class="category-page__order-by__container">
    <div class="category-page__order-by">
      <CustomSelect
        class="category-page__order-by__select"
        select-name="category-sort"
        :select-options="sortingOptions"
        placeholder="Sort products by"
        @selectChange="(v) => handleSortChange(v)"
        :selected-value="currentSorting"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { CustomSelect } from '~/components/General/FormElements';
import { useCategoryPage, useUiState } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'CategoryOrderBy',
  components: { CustomSelect },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { availableSortingOptions, changeSorting, currentSorting } =
      useCategoryPage(props.categoryId);
    const { isFilterSidebarOpen, toggleFilterSidebar } = useUiState();
    const trans = useI18n();

    const sortingOptions = computed(() =>
      availableSortingOptions.value.map((option) => ({
        ...option,
        label: trans.t(option.label),
      }))
    );

    const handleSortChange = async (sort) => {
      await changeSorting(sort);
      if (isFilterSidebarOpen.value) toggleFilterSidebar(props.categoryId);
    };

    return {
      handleSortChange,
      sortingOptions,
      currentSorting,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-page__order-by {
  &__container {
    display: none;
    gap: 0.75rem;
    margin: 1rem 0 2.1875rem 0;
    width: 100%;
    justify-content: flex-end;
  }
  &__label {
    @include text-16x20-black;
  }
  @include flex-center;
  width: fit-content;
  min-width: 15rem;
  display: none;
}
@include from-desktop-min {
  .category-page__order-by {
    &__container {
      display: unset;
      width: unset;
      margin: unset;
    }
    display: flex;
    &__select {
      border: 0.0625rem solid var(--c-text-disabled);
    }
  }
}
</style>
