<template>
  <div class="yf-custom-product-card">
    <SfProductCardCustom
      :link="link"
      :image-alt="imageAlt"
      :title="title"
      :regular-price="regularPrice"
      :special-price="specialPrice"
      :omnibus-price="omnibusPrice"
      :omnibus-discount-percentage="omnibusDiscountPercentage"
      :discount-percentage="discountPercentage"
      :product="product"
      :colors="colors"
      :items-in-wishlist="itemsInWishlist"
    >
      <template #title>
        <SfButton
          :link="link"
          class="sf-button--pure sf-product-card__link"
          data-testid="product-link"
        >
          <h3 class="sf-product-card__title">
            {{ title }}
          </h3>
        </SfButton>
      </template>
    </SfProductCardCustom>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import SfProductCardCustom from './SfProductCardCustom.vue';

export default defineComponent({
  name: 'CustomProductCard',
  components: {
    SfProductCardCustom,
    SfButton,
  },
  props: {
    product: {
      type: Object,
      required: false,
      default() {},
    },
    image: {
      type: [String, Array],
      required: false,
      default() {
        return '' || [];
      },
    },
    imageAlt: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    regularPrice: {
      type: String,
      required: false,
      default: '',
    },
    specialPrice: {
      type: String,
      required: false,
      default: '',
    },
    omnibusPrice: {
      type: String,
      required: false,
      default: '',
    },
    omnibusDiscountPercentage: {
      type: Number,
      required: false,
      default: 0,
    },
    discountPercentage: {
      type: Number,
      required: false,
      default: 0,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    colors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    itemsInWishlist: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>

<style lang="scss">
.yf-custom-product-card {
  border-top: solid 0.5rem var(--c-yellow);
  overflow: hidden;
  height: auto;
  .sf-product-card {
    width: 100%;
    max-width: 100%;
    border: unset;
    padding: unset;
    height: 100%;
    position: relative;
    &__title {
      text-align: left;
      line-height: var(--line-height-25);
      font-family: var(--font-family-albert-sans);
      font-weight: bold;
      font-size: var(--font-size-16);
      letter-spacing: 0.01rem;
      color: var(--c-black);
      overflow: unset;
      white-space: normal;
      text-overflow: unset;
      margin: 0.3125rem 0 0;
      padding: 0 0.625rem;
      @include to-mobile-max {
        font-size: var(--font-size-13);
        line-height: var(--line-height-16);
      }
    }
    &__price {
      justify-content: start;
      align-items: center;
      margin: 0.625rem 0 0 0;
      @include text-16x25-regular;
      .sf-price__special {
        order: -1;
      }
      .sf-price__old {
        margin-left: 0.5rem;
        color: #9fa6ad;
      }
    }
    .sf-button.sf-button--pure {
      &.sf-product-card__wishlist-icon {
        opacity: 9;
        position: absolute;
        top: 0.625rem;
        border-radius: 0;
        border: 0;
        right: 0.625rem;
        img {
          height: var(--h-auto);
          width: var(--w-auto);
          @include to-mobile-max {
            width: 0.875rem;
          }
        }
      }
    }
    .sf-product-card__colors-badge,
    &__color {
      margin-left: 4px;
      margin-right: 4px;
      margin-top: 10px;
    }

    &__colors,
    .sf-product-card__colors-badge {
      padding: 0.625rem;
      --color-picker-position: relative;
      --color-picker-justify-content: flex-start;
      --color-picker-padding: 0;
      --color-picker-height: 10px;

      .sf-overlay {
        background: white;
      }
    }
    .sf-product-card__colors-badge {
      padding: 0.25rem !important;
      box-shadow: none;
    }
    .sf-color-picker {
      z-index: unset !important;
      margin-top: auto;
    }
    .sf-color-picker__colors {
      justify-content: flex-start;
    }

    .sf-color,
    .sf-product-card__colors-badge {
      --badge-height: 1.25rem;
      --badge-width: 1.25rem;
      --color-height: 1.25rem;
      --color-width: 1.25rem;
      margin: 0 0.2em;
      pointer-events: none;
      font-size: 0.7rem;
      line-height: 1rem;
      z-index: auto;
    }
    @include to-mobile-max {
      padding-bottom: 1rem;
    }
    &__labels {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      @include text-13x16-regular;
      display: flex;
      gap: 0.3125rem;
      &__label {
        padding: 0.2rem;
        width: fit-content;
        background-color: var(--c-red);
        color: var(--c-white);
      }
    }
  }
  .sf-product-card__image-wrapper {
    .sf-product-card__link {
      overflow: hidden;
      width: var(--w-full);
      position: var(--relative);
      display: var(--display);
      .sf-image--wrapper {
        position: var(--absolute);
        top: var(--top-0);
        left: var(--left-0);
        display: var(--flex);
        align-items: var(--align-items);
        justify-content: center;
        width: var(--w-full);
        padding: 0;
        img {
          height: auto !important;
          width: auto !important;
          max-width: var(--w-full);
          max-height: var(--h-full);
          margin: auto;
        }
      }
    }
  }

  .yf-product-content {
    width: 100%;
  }
}

@media (max-width: 768.98px) {
  .yf-custom-product-card {
    .sf-product-card {
      --product-card-box-shadow: unset;
      --yf-product-content-transform: translateX(0);
      --yf-product-content-opacity: 1;
      --yf-product-content-visibility: visible;
      --yf-product-content-events: all;
      --product-card-wishlist-icon-opacity: 1;
      .sf-overlay {
        background: unset;
      }

      .smartphone-only {
        display: none;
      }

      &__wishlist-icon {
        .sf-icon {
          --icon-height: 1.875rem;
          --icon-width: 1.6875rem;
        }
      }

      .sf-price__old {
        margin-left: 0.1875rem;
        margin-right: 0;
      }

      .sf-price__special {
        margin-left: 0;
        margin-right: 0.1875rem;
      }
    }
  }
}
</style>
