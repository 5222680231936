<template>
  <div class="products">
    <div
      v-if="products && products.length > 0 && !loadingProducts"
      class="products-list-loader"
    >
      <div class="category-products">
        <div class="category-products__heading">
          <CategoryCounter
            class="category-products__counter desktop-only"
            :category-id="categoryId"
          />
          <CategoryOrderBy :category-id="categoryId" />
          <CategorySubHeading :category-id="categoryId" />
        </div>
        <div class="products-list">
          <CustomProductCard
            v-for="product in products"
            :key="product.uid"
            class="product desktop-product"
            :link="localePath(buildProductUrl(product))"
            :title="getProductTitle(product)"
            :image-alt="getProductImageAlt(product, 'thumbnail')"
            :regular-price="$n(getProductPrice(product).regular, 'currency')"
            :special-price="
              getProductPrice(product).special
                ? $n(getProductPrice(product).special, 'currency')
                : null
            "
            :omnibus-price="
              product.omnibus_price
                ? $n(product.omnibus_price, 'currency')
                : null
            "
            :omnibus-discount-percentage="
              calculateOmnibusDiscountPercentage(product)
            "
            :discount-percentage="calculateDiscountPercentage(product)"
            :product="product"
            :colors="
              getSfColorsFromAttributes(getProductColorAttribute(product))
            "
            :items-in-wishlist="itemsInWishlist"
          />
        </div>
        <CategoryPagination :category-id="categoryId" />
      </div>
    </div>
    <div v-else-if="loadingProducts" class="products-skeleton">
      <SfSkeleton v-for="n in 14" :key="n" />
    </div>
  </div>
</template>

<script>
import productPlaceholder from '~/static/icons/placeholder-list.svg';
import { productGetters } from '@gemini-vsf/composables';
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { useUrlHelpers, useWishlist, useCategoryPage } from '~/composables';
import CustomProductCard from '~/components/CustomProductCard.vue';
import { SfSkeleton } from '@storefront-ui/vue';
import { isEmpty } from 'lodash-es';
import CategoryCounter from '~/components/Category/CategoryCounter.vue';
import {
  getProductColorAttribute,
  getSfColorsFromAttributes,
} from '~/helpers/product/productData';
import CategorySubHeading from '~/components/Category/CategorySubHeading.vue';
import CategoryPagination from './CategoryPagination.vue';
import CategoryOrderBy from './CategoryOrderBy.vue';

export default defineComponent({
  name: 'ProductList',
  components: {
    CategorySubHeading,
    CategoryCounter,
    CustomProductCard,
    SfSkeleton,
    CategoryPagination,
    CategoryOrderBy,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { buildProductUrl } = useUrlHelpers();
    const { itemsInWishlist } = useWishlist();
    const { loadingProducts } = useCategoryPage(props.categoryId);

    const getProductTitle = (product) => {
      return isEmpty(productGetters.getName(product))
        ? productGetters.getShortDescription(product)
        : productGetters.getName(product);
    };

    const calculateOmnibusDiscountPercentage = (product) => {
      const omnibusPrice = product.omnibus_price;
      return omnibusPrice
        ? Math.round(
            Math.abs(
              (1 - omnibusPrice / productGetters.getPrice(product).regular) *
                100
            )
          )
        : null;
    };
    const isHovering = ref(false);

    const calculateDiscountPercentage = (product) => {
      return productGetters.getPrice(product).special
        ? Math.round(
            (1 -
              productGetters.getPrice(product).special /
                productGetters.getPrice(product).regular) *
              100
          )
        : null;
    };

    return {
      buildProductUrl,
      getRolloverImage: productGetters.getRolloverImage,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName: productGetters.getName,
      getProductPrice: productGetters.getPrice,
      getProductImageAlt: productGetters.getProductImageAlt,
      calculateOmnibusDiscountPercentage,
      calculateDiscountPercentage,
      getProductTitle,
      getProductColorAttribute,
      getSfColorsFromAttributes,
      itemsInWishlist,
      productPlaceholder,
      loadingProducts,
      isHovering,
    };
  },
});
</script>

<style lang="scss">
.products-skeleton {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include from-tablet-max-to-tablet-1500 {
    grid-template-columns: repeat(3, 1fr);
  }
  @include for-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
  gap: 1rem;
  .sf-skeleton {
    height: 18.75rem;
  }
}
</style>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.products {
  grid-area: product-list;
  position: relative;
  #scroll-loader {
    height: 50px;
  }
}
@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.products-list {
  display: grid;
}
.category-products {
  margin: 0 auto;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 1023px) {
  .products-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.875rem 0.625rem;
  }
}
@include from-tablet-max-to-tablet-1500 {
  .products-list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.375rem 0.625rem;
    grid-template-rows: repeat(auto, auto);
    padding: 2rem 1rem;
  }
}
@include from-desktop-1500 {
  .products-list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4.375rem 0.625rem;
    grid-template-rows: repeat(auto, auto);
    padding: 5rem 1rem;
  }
}
</style>
