<template>
  <div class="free-shipping-block">
    <i18n
      tag="span"
      class="free-shipping-block__paragraph"
      path="freeShippingLimit"
      v-if="freeShippingAmount > 0"
    >
      <span class="free-shipping-block__paragraph bold">
        {{ $n(freeShippingAmount, 'currency') }}
      </span>
    </i18n>
    <span class="free-shipping-block__paragraph" v-else>
      {{ $gt('Congratulations! You have reached the free shipping!') }}
    </span>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import {
  useCheckout,
  useGeminiApi,
  useTranslation,
  useCart,
} from '~/composables';
import { cartGetters } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'FreeShipping',
  setup() {
    const {
      route: {
        value: { fullPath },
      },
    } = useContext();
    const { $gt } = useTranslation('translations');
    const { findPayloadData } = useGeminiApi();
    const freeShippingData = ref();
    const { cartId } = useCart();
    const { cart, order } = useCheckout(cartId.value);
    const totals = computed(() =>
      order.value
        ? {
            subtotal: order.value.total_item,
            total: order.value.total,
          }
        : cartGetters.getTotals(cart.value)
    );
    const freeShippingAmount = computed(
      () =>
        Number(freeShippingData?.value?.amount) -
        Number(totals?.value?.subtotal)
    );

    onMounted(async () => {
      try {
        const currentLocale = fullPath?.split('/')?.[1] || 'it';
        const { docs } = await findPayloadData(
          'settings',
          {
            identifier: {
              equals: 'checkoutSettings',
            },
          },
          currentLocale
        );
        freeShippingData.value = docs
          .find((d) => d.identifier === 'checkoutSettings')
          .settings.find(
            (s) => s.genericSettingsTypeIdentifier === 'freeShippingSetting'
          ).genericSettings[0].genericSettingsJSON.freeShipping;
      } catch (error) {
        console.error('FreeShipping ~ onMounted ~ error:', error);
      }
    });

    return { $gt, freeShippingData, freeShippingAmount, totals };
  },
});
</script>

<style lang="scss">
.free-shipping-block {
  background: var(--c-search-background);
  padding: 0.4375rem;
  margin: 0.5rem 0;
  &__paragraph {
    font-size: var(--font-size-14);
    color: var(--c-primary);
    letter-spacing: 0.008125rem;
    line-height: 1.5rem;
    &.bold {
      font-weight: bold;
    }
  }
}
</style>
