<template>
  <div class="checkout-header">
    <div class="checkout-header__content">
      <nuxt-link
        class="checkout-header__content__back"
        :to="localePath('/cart')"
      >
        {{ $gt('Back') }}
      </nuxt-link>
      <nuxt-link class="checkout-header__content__logo" :to="localePath('/')">
        <nuxt-img
          class="checkout-header__content__logo__icon"
          src="/logo_white.jpg"
          alt="Tre Ponti logo"
        />
      </nuxt-link>
      <span class="checkout-header__content__hidden">HIDDEN</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'CheckoutHeader',
  setup() {
    const { $gt } = useTranslation();

    return {
      $gt,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkout-header {
  background-color: var(--c-primary);
  height: 3.5rem;
  &__content {
    @include paragraph-regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin: 0 auto;
    padding: 0 1.5rem;
    height: 100%;
    @include desktop-boxed-inner;
    &__back {
      @include pointer;
      @include menu;
      font-weight: var(--font-weight-normal);
      color: var(--c-white);
    }
    &__logo {
      height: 100%;
      &__icon {
        height: 3.5rem;
        width: 3.5rem;
      }
    }
    &__hidden {
      visibility: hidden;
    }
    @include from-tablet-min {
      padding: 0 2rem;
    }
  }
}
@include from-desktop-min {
  .checkout-header {
    height: 5rem;
    &__content {
      &__logo {
        &__icon {
          height: 5rem;
          width: 5rem;
        }
      }
    }
  }
}
</style>
