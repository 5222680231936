<template>
  <div class="category-page__filters">
    <SfAccordion :multiple="true" :open="openAccordions">
      <SfAccordionItem
        v-for="(filter, filterIndex) in selectableAvailableFilters"
        :key="`category-filters-${filter.label}-${filterIndex}`"
        :header="$gt(filterLabelsMap[filter.label] || filter.label)"
      >
        <CategoryFilterRow
          v-for="(option, optionIndex) in filter.options"
          :key="`category-filters-${categoryId}-${optionIndex}-${option.value}-checkbox-${randomKey}`"
          :label="option.label"
          :selected="option.active"
          @click.native="handleAddOrRemoveFilter(filter.label, option.value)"
        />
      </SfAccordionItem>
    </SfAccordion>
    <div class="category-page__filters__ctas">
      <CustomButton
        @click="handleApplyFilters"
        theme="black"
        specific-width="57%"
      >
        {{ $t('Apply filters') }}
      </CustomButton>
      <div
        class="category-page__filters__ctas__reset-filters"
        @click="resetFilters"
      >
        {{ $t('Reset filters') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  shallowRef,
  ref,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';
import { useCategoryPage, useTranslation, useUiState } from '~/composables';
import { CustomButton } from '~/components/General/FormElements';
import { SfAccordion } from '@storefront-ui/vue';
import CategoryFilterRow from '~/components/Category/CategoryFilterRow.vue';
import dataManipulation from '~/helpers/dataManipulation';
import { scrollToTop } from '~/composables/useCategoryPage/_helpers';

export default defineComponent({
  name: 'CategoryFilters',
  components: { CustomButton, SfAccordion, CategoryFilterRow },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      selectableAvailableFilters,
      currentFilters,
      addOrRemoveFilter,
      loadProducts,
      filterLabelsMap,
      resetFilters: resetFiltersComposable,
    } = useCategoryPage(props.categoryId);
    const { generateUniqueKey } = dataManipulation();
    const randomKey = shallowRef(generateUniqueKey());
    const openAccordions = ref('');
    const { isFilterSidebarOpen, toggleFilterSidebar } = useUiState();
    const { $gt } = useTranslation('translations');

    const disableResetFilters = computed(
      () =>
        Object.keys(currentFilters.value.filters).length === 0 ||
        Object.values(currentFilters.value.filters).every((v) => v.length === 0)
    );

    const handleApplyFilters = async () => {
      if (isFilterSidebarOpen.value) toggleFilterSidebar(props.categoryId);
      scrollToTop();
      await loadProducts();
    };

    const handleAddOrRemoveFilter = (filter, value) => {
      addOrRemoveFilter(filter, value);
    };

    const resetFilters = async () => {
      await resetFiltersComposable();
    };

    onMounted(() => {
      openAccordions.value =
        selectableAvailableFilters.value
          ?.map((filter) =>
            filter.options.find((o) => o.active)
              ? filterLabelsMap[filter.label] || filter.label
              : false
          )
          .filter(Boolean) || '';
    });

    return {
      selectableAvailableFilters,
      currentFilters,
      filterLabelsMap,
      handleAddOrRemoveFilter,
      handleApplyFilters,
      randomKey,
      openAccordions,
      resetFilters,
      disableResetFilters,
      $gt,
    };
  },
});
</script>

<style lang="scss">
.category-page__filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 12.6875rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .sf-accordion-item__header {
    width: 100% !important;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--c-primary-light) !important;
    padding: 1rem 0 0.75rem 0 !important;
    font-size: var(--font-size-16) !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    &.is-open {
      border-bottom: unset !important;
    }
  }
  &__ctas {
    display: none;
  }
  .sf-accordion-item {
    &__header.is-open {
      --chevron-color: var(--c-black) !important;
    }
    &__content {
      padding: 0;
      border-bottom: 0.0625rem solid var(--c-primary-light) !important;
      max-height: 12rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .sf-chevron__bar:after {
    background: var(--c-black) !important;
    background-color: var(--c-black) !important;
  }
}
@include from-desktop-min {
  .category-page__filters {
    height: auto;
    padding-bottom: 0;
    &__ctas {
      padding-top: 1.875rem;
      display: flex;
      overflow: unset;
      justify-content: space-between;
      align-items: baseline;
      &__reset-filters {
        color: var(--c-red);
        @include text-16x25-regular;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .sf-accordion-item {
      &__content {
        max-height: 15rem;
        margin: 0.5rem 0;
      }
    }
  }
}
</style>
