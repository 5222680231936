<template>
  <div class="checkout-footer">
    <FooterLegal v-if="legal" :items="legal" layout="checkout" />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
import { FooterComponents } from '~/components/FooterComponents';

export default defineComponent({
  name: 'CheckoutFooter',
  components: {
    ...FooterComponents,
  },
  setup() {
    const { menu: footerMenu, search: searchFooterMenu } =
      useMenu('footerMenu');
    const legal = ref();

    onMounted(async () => {
      if (!footerMenu.value?.items) {
        await searchFooterMenu({ code: 'footer-menu' });
      }
      legal.value = footerMenu.value?.items?.find(
        (item) => item?.uid === '66178f54d4902872c0a18ea8'
      );
    });

    return {
      legal,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkout-footer {
  background-color: var(--c-black);
}
</style>
