var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfPages > 1),expression:"numberOfPages > 1"}],staticClass:"category-page__pagination"},[_c('div',{staticClass:"category-page__pagination__prev category-page__pagination__navigation",class:_vm.currentPage === 1 && 'category-page__pagination__navigation--disabled',on:{"click":function($event){return _vm.handleChangePage(_vm.currentPage - 1)}}},[_c('span',[_vm._v("<")])]),_vm._v(" "),_c('div',{staticClass:"category-page__pagination__pages"},[_vm._l((_vm.pageNumbersToShow),function(n){return _c('div',{key:("pagination-" + n),staticClass:"category-page__pagination__pages__numbers",class:[
        {
          'category-page__pagination__pages__numbers__current':
            _vm.currentPage === n,
          'category-page__pagination__pages__numbers__no-border':
            _vm.numberOfPages <= 3 &&
            _vm.currentPage >= _vm.numberOfPages - 3 &&
            n === _vm.numberOfPages,
        } ]},[_c('span',{on:{"click":function($event){return _vm.handleChangePage(n)}}},[_vm._v(_vm._s(n))])])}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.currentPage >= _vm.numberOfPages - 3)),expression:"!(currentPage >= numberOfPages - 3)"}],staticClass:"category-page__pagination__pages__numbers"},[_vm._v("\n      ...\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfPages > 3),expression:"numberOfPages > 3"}],staticClass:"category-page__pagination__pages__numbers",class:_vm.currentPage === _vm.numberOfPages &&
        'category-page__pagination__pages__numbers__current',on:{"click":function($event){return _vm.handleChangePage(_vm.numberOfPages)}}},[_vm._v("\n      "+_vm._s(_vm.numberOfPages)+"\n    ")])],2),_vm._v(" "),_c('div',{staticClass:"category-page__pagination__next category-page__pagination__navigation",class:_vm.currentPage === _vm.numberOfPages &&
      'category-page__pagination__navigation--disabled',on:{"click":function($event){return _vm.handleChangePage(_vm.currentPage + 1)}}},[_c('span',[_vm._v(">")])]),_vm._v(" "),_c('div',{staticClass:"category-page__pagination__hidden"},_vm._l((_vm.numberOfPages),function(page){return _c('a',{key:("hidden-paginator-" + page),attrs:{"href":(_vm.geminiBaseUrl + "?page=" + page)}},[_vm._v("\n      "+_vm._s(page)+"\n    ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }