<template>
  <div class="category-filter__row">
    <div class="category-filter__row__checkbox">
      <div class="category-filter__row__checkbox__box" v-visible="selected">
        &#10003;
      </div>
    </div>
    <div
      class="category-filter__row__label"
      :class="selected && 'category-filter__row__label__selected'"
    >
      {{ $gt(label) }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'CategoryFilterRow',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $gt } = useTranslation('translations');
    return { $gt };
  },
});
</script>

<style lang="scss">
.category-filter__row {
  display: flex;
  align-items: center;
  background-color: var(--c-grey);
  padding: 0.5rem;
  &__label {
    @include text-16x22-regular;
    padding-left: 0.9375rem;
    &__selected {
      @include text-16x20-black;
      font-weight: 400;
    }
  }
  &__checkbox {
    border: 0.0625rem solid var(--c-black);
    padding-right: 0.625rem;
    height: 1.25rem;
    width: 1.25rem;
    position: relative;
    &__box {
      font-size: var(--font-size-20);
      line-height: 1.25rem;
    }
  }
}
@include from-desktop-min {
  .category-filter__row {
    @include pointer;
    padding: 0.3125rem;
  }
}
</style>
