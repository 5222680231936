<template>
  <div class="category-page__pagination" v-show="numberOfPages > 1">
    <div
      @click="handleChangePage(currentPage - 1)"
      class="category-page__pagination__prev category-page__pagination__navigation"
      :class="
        currentPage === 1 && 'category-page__pagination__navigation--disabled'
      "
    >
      <span>&lt;</span>
    </div>
    <div class="category-page__pagination__pages">
      <div
        v-for="n in pageNumbersToShow"
        :key="`pagination-${n}`"
        class="category-page__pagination__pages__numbers"
        :class="[
          {
            'category-page__pagination__pages__numbers__current':
              currentPage === n,
            'category-page__pagination__pages__numbers__no-border':
              numberOfPages <= 3 &&
              currentPage >= numberOfPages - 3 &&
              n === numberOfPages,
          },
        ]"
      >
        <span @click="handleChangePage(n)">{{ n }}</span>
      </div>
      <div
        v-show="!(currentPage >= numberOfPages - 3)"
        class="category-page__pagination__pages__numbers"
      >
        ...
      </div>
      <div
        v-show="numberOfPages > 3"
        @click="handleChangePage(numberOfPages)"
        class="category-page__pagination__pages__numbers"
        :class="
          currentPage === numberOfPages &&
          'category-page__pagination__pages__numbers__current'
        "
      >
        {{ numberOfPages }}
      </div>
    </div>
    <div
      @click="handleChangePage(currentPage + 1)"
      class="category-page__pagination__next category-page__pagination__navigation"
      :class="
        currentPage === numberOfPages &&
        'category-page__pagination__navigation--disabled'
      "
    >
      <span>&gt;</span>
    </div>
    <div class="category-page__pagination__hidden">
      <a
        :href="`${geminiBaseUrl}?page=${page}`"
        v-for="page in numberOfPages"
        :key="`hidden-paginator-${page}`"
      >
        {{ page }}
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';
import { useCategoryPage } from '~/composables';

export default defineComponent({
  name: 'CategoryPagination',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      $vsf: {
        $gemini: {
          config: { geminiBaseUrl },
        },
      },
    } = useContext();
    const { numberOfPages, currentPage, changePageNumber } = useCategoryPage(
      props.categoryId
    );

    const pageNumbersToShow = computed(() => {
      if (numberOfPages.value <= 3) {
        return Array.from({ length: numberOfPages.value }, (_, i) => i + 1);
      }
      const startPage =
        currentPage.value >= numberOfPages.value - 3
          ? numberOfPages.value - 3
          : Math.floor((currentPage.value - 1) / 3) * 3 + 1;
      const pages = [startPage, startPage + 1, startPage + 2];
      return pages.filter((page) => page <= numberOfPages.value);
    });

    const handleChangePage = async (pageNumber) => {
      await changePageNumber(pageNumber);
    };

    return {
      numberOfPages,
      pageNumbersToShow,
      currentPage,
      handleChangePage,
      geminiBaseUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-page__pagination {
  width: 100%;
  aspect-ratio: 326/37;
  border-radius: 0.75rem;
  margin: 3rem auto;
  padding: 0.25rem 0.375rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &__navigation {
    @include text-16x25-regular;
    @include pointer;
    display: flex;
    &__text {
      display: none;
    }
    &--disabled {
      opacity: 0.5;
      &:hover {
        cursor: not-allowed;
        @include no-selection;
      }
      cursor: not-allowed;
    }
  }
  &__pages {
    background-color: var(--c-white);
    border-radius: 0.75rem;
    width: fit-content;
    height: 100%;
    gap: 1rem;
    display: flex;
    &__numbers {
      @include flex-center;
      @include text-16x25-regular;
      @include pointer;
      @include no-selection;
      padding: 0 1rem;
      border: 1px solid var(--c-black);
      &__current {
        color: var(--c-white);
        background-color: var(--c-black);
      }
    }
  }
  &__hidden {
    display: none;
  }
}
@include from-desktop-min {
  .category-page__pagination {
    width: 40%;
    aspect-ratio: 74/7;
    padding: 0.25rem 1rem;
    &__navigation {
      @include text-16x25-regular;
      gap: 1rem;
      &__text {
        display: inline;
      }
    }
    &__pages {
      &__numbers {
        @include text-16x25-regular;
      }
    }
  }
}
</style>
