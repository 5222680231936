<template>
  <div class="category-heading">
    <nuxt-img
      v-if="categoryHeroDesktopImage && categoryHeroDesktopImage.url"
      class="category-heading__hero desktop-only"
      :src="categoryHeroDesktopImage.url"
      :alt="categoryHeroDesktopImage.alt"
    />
    <div class="category-top" :class="{ 'no-img': !categoryHeroDesktopImage }">
      <SfBreadcrumbs
        class="category-top__breadcrumbs"
        v-if="breadcrumbs && breadcrumbs.length > 0"
        :breadcrumbs="breadcrumbs"
      />
      <nuxt-img
        v-if="categoryHeroMobileImage && categoryHeroMobileImage.url"
        class="category-heading__hero smartphone-only"
        :src="categoryHeroMobileImage.url"
        :alt="categoryHeroMobileImage.alt"
      />
      <h1 class="category-top__name" v-if="categoryData">
        {{ categoryData.name }}
      </h1>
      <h2 class="category-top__description" v-if="categoryDescription">
        {{ categoryDescription }}
        <strong
          @click="isExpanded = !isExpanded"
          style="cursor: pointer"
          v-if="categoryDescription.length > 230"
        >
          {{ isExpanded ? $t('Read less') : $t('Read more') }}
        </strong>
      </h2>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  useFetch,
  ref,
  useRoute,
  computed,
} from '@nuxtjs/composition-api';
import { useCategoryPage, useGeminiApi, useTranslation } from '~/composables';
import { SfBreadcrumbs } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CategoryHeading',
  components: { SfBreadcrumbs },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { categoryData, loadingCategoryData } = useCategoryPage(
      props.categoryId
    );
    const { findPayloadData } = useGeminiApi();
    const { $gt } = useTranslation('translations');
    const categoryHeroImage = ref({});
    const route = useRoute();
    const { fullPath } = route.value;
    const categoryHeroDesktopImage = ref({});
    const categoryHeroMobileImage = ref({});
    const isExpanded = ref(false);
    const categoryDescription = computed(() => {
      if (
        !isExpanded.value &&
        categoryData?.value?.description?.length >= 230
      ) {
        return `${categoryData?.value?.description.slice(0, 230)}...`;
      }
      return categoryData?.value?.description;
    });

    useFetch(async () => {
      try {
        const currentLocale = fullPath?.split('/')?.[1] || 'it';
        const { docs } = await findPayloadData(
          'categoryHero',
          {
            categoryUid: {
              equals: props.categoryId,
            },
          },
          currentLocale
        );
        categoryHeroImage.value = docs.find(
          (d) => d.categoryUid === props.categoryId
        );
        categoryHeroDesktopImage.value =
          categoryHeroImage.value?.categoryHeroDesktopImage;
        categoryHeroMobileImage.value =
          categoryHeroImage.value?.categoryHeroMobileImage;
      } catch (error) {
        console.error('CategoryHeading ~ useFetch ~ error:', error);
      }
    });

    return {
      categoryData,
      loadingCategoryData,
      categoryDescription,
      isExpanded,
      categoryHeroMobileImage,
      categoryHeroDesktopImage,
      $gt,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-heading {
  position: relative;
  max-width: 100vw;
  min-height: 20rem;
  .category-top {
    @include for-desktop {
      position: absolute;
      top: 0;
      left: 0;
      &.no-img {
        position: relative !important;
        background: var(--c-white);
      }
    }
    &__breadcrumbs {
      padding: 1.25rem;
      --breadcrumbs-list-item-seperator: '>';
      font-family: var(--font-family-albert-sans);
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 1.5625rem;
      @include for-desktop {
        padding: 1.25rem 0 1.25rem 3.125rem;
      }

      .sf-link.sf-breadcrumbs__breadcrumb {
        @include text-13x16-regular;
        color: var(--c-black);
      }

      @include from-desktop-min {
        display: block;
      }
    }

    &__name,
    &__description {
      font-family: var(--font-family-albert-sans);
      box-sizing: content-box;
      padding: 1.25rem;
      @include for-desktop {
        padding: 1.25rem 0 2.5rem 12.25rem;
        width: 44rem;
        max-width: 44rem;
      }
    }

    &__name {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.5rem;
      @include for-desktop {
        font-size: 2.8125rem;
        line-height: 2.8125rem;
        letter-spacing: -0.028125rem;
      }
    }
    &__description {
      font-size: 0.875rem;
      line-height: 1.4375rem;
      letter-spacing: 0.00875rem;
      font-weight: normal;
    }
  }
  &__hero {
    max-width: 100vw;
  }
}
</style>
